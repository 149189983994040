import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "./menuItem";

const Header = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => setMenuVisible(prev => !prev);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menuItems = [
    { to: "/", label: "THUNDER©" },
    { to: "/prologue", label: "Prologue" },
    { to: "/update-logs", label: "Update Logs" },
    { to: "/iplaymore", label: "iPlayMore" },
  ];

  return (
    <>
      <div className={`sticky top-0 z-[1500] flex w-full h-[36px] md:h-[60px] lg:h-[90px] bg-cover items-center px-1 py-4 md:px-10 justify-between -mt-1`} style={{ backgroundImage: 'url(assets/img/header-bg.png)', backgroundSize: 'cover' }}>
        <div className="flex items-center gap-1 md:gap-4">
          <Link to="/iplaymore"><img src="assets/img/logo-sm.png" className="w-[75px] lg:w-[156px] h-[28px] lg:h-[58px]" /></Link>
          <Link to="/"><img src="assets/img/logo-left-sm.png" className="w-[140px] lg:w-[290px] h-[13px] lg:h-[28px]" /></Link>
        </div>
        <img src="assets/img/three-dot.png" className="block md:hidden w-[29px] pr-2 cursor-pointer" onClick={toggleMenu} />
        <div className="hidden md:flex items-center gap-8 lg:gap-11 xl:text-[24px] lg:pr-11">
          {menuItems.map(item => (
            <MenuItem key={item.to} to={item.to}>{item.label}</MenuItem>
          ))}
        </div>
      </div>
      {isMenuVisible && isMobile && (
        <div className="fixed inset-0 bg-[black] flex flex-col items-center pt-10 z-[1499] px-1">
          {menuItems.map(item => (
            <MenuItem onClick={toggleMenu} key={item.to} mobile={true} to={item.to}>{item.label}</MenuItem>
          ))}
        </div>
      )}
    </>
  );
};

export default Header;